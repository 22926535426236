<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Master Poli</strong>
              </h5>
            </template>
            <!-- <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button  variant="primary" v-b-modal.modal-input-poli
                  ><CIcon name="cil-plus" /> Tambah Data</b-button
                >
              </b-col>
            </b-row> -->
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  label="Cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Ketik disini untuk mencari ..."
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger"
                        >Hapus</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <!-- <b-button
                      
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit-poli
                      @click="is_data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-poli
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    > -->

                    <b-button
                      
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Integrasi Satu Sehat'"
                      @click="updateSatuSehat(item.item)"
                      :disabled="item.item.satu_sehat_id != null"
                      ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                    >
                    <b-button
                      v-if="item.item.status_aktif_poli == 1 && (role == 'super admin' || role == 'admin')"
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Nonaktifkan Poli'"
                      @click="nonaktif(item.item)"
                    >
                       {{ item.actions }}
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                          <path d="M7.5 1v7h1V1h-1z"/>
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                        </svg>
                    </b-button>
                    <b-button
                      v-if="item.item.status_aktif_poli == 0 && (role == 'super admin' || role == 'admin')"
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Aktifkan Poli'"
                      @click="aktif(item.item)"
                    >
                       {{ item.actions }}
                       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-power" viewBox="0 0 16 16">
                          <path d="M7.5 1v7h1V1h-1z"/>
                          <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z"/>
                        </svg>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      :list_kode="list_kode"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-edit
      :list_kode="list_kode"
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <modal-delete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
  },
  name: "master_dokter",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      list_kode: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nama_poli",
          label: "Nama Poli",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_poli_puskesmas",
          label: "Kode Poli Puskesmas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kode_poli_bpjs",
          label: "Kode Poli BPJS",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "poli_sakit",
          label: "Poli Sakit",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_is_lab",
          label: "Laboratorium",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_poli_bpjs",
          label: "namabpjs",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: " text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map(el => el.key)
    },
    role() {
      return this.$store.state.user_role
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      this.tableBusy = true;
      let res = await this.$axios.post("/ms_poli/list", {
        halaman: 0,
        jumlah: 1000,
      });
      console.log(res);
      this.items = res.data.data;
      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;
        x.nama_is_lab = x.is_lab ? 'ya' : 'tidak'
      }
      this.totalRows = this.items.length;
      this.tableBusy = false;

      // let kode_poli = await this.$axios.post("/integrasi_pcare/get_poli");
      // console.log(kode_poli, 'kodepoli');
      // this.list_kode = kode_poli.data.data.map((item) => {
      //   item.nama = `${item.kdPoli} (${item.nmPoli})`;
      //   item.text = `${item.kdPoli} (${item.nmPoli})`;
      //   item.value = item.kdPoli;
      //   return item;
      // });
      // this.list_kode = kode_poli.data.data.map((item) => {
      //   item.text = `${item.kdPoli} (${item.nmPoli})`;
      //   item.value = item.kdPoli;
      //   return item;
      // });
      // console.log(this.list_kode, "ini list kode");
    },
    aktif(x){
      let vm = this
      vm.$axios.post("/ms_poli/update", {
        id: x.ms_poli_id,
        status_aktif_poli: 1
      })
      .then((res) => {
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE STATUS POLI" })
          vm.$forceUpdate()
          vm.getDatas()
        }else{
          vm.$store.commit("set_alert", { variant: "success", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
      })
    },
    nonaktif(x){
      let vm = this
      vm.$axios.post("/ms_poli/update", {
        id: x.ms_poli_id,
        status_aktif_poli: 0
      })
      .then((res) => {
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE STATUS POLI" })
          vm.$forceUpdate()
          vm.getDatas()
        }else{
          vm.$store.commit("set_alert", { variant: "success", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
      })
    },
    updateSatuSehat(x){
      let vm = this
      let organization_id = vm.$store.state.organization_id
      vm.$axios.post("/satu_sehat/location/get_location", {
        organization: organization_id,
        name: x.nama_poli
      })
      .then((res) => {
        if(res.data.status == 200 && res.data.message == "sukses"){
          if(res.data.data[0].entry.length){
            let id_satu_sehat = res.data.data[0].entry[0].resource.id
            vm.$axios.post("/ms_poli/update", {
              id: x.ms_poli_id,
              satu_sehat_id: id_satu_sehat,
            })
            .then((res) => {
              if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE DATA SATU SEHAT" })
                vm.getDatas()
              }
              else{
                vm.$store.commit("set_alert", { variant: "success", msg: res.data.message })
              }
            })
            .catch((err) => {
              vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
            })
          }else {
            vm.$axios.post("/satu_sehat/location/add_location", {
              organization_id: organization_id,
              ms_poli_id: x.ms_poli_id,
              name_location: x.nama_poli,
            })
            .then((res) => {
              // console.log(res);
              if(res.data.status == 200 && res.data.message == "sukses"){
                let id_satu_sehat = res.data.data[0].id
                vm.$axios.post("/ms_poli/update", {
                  id: x.ms_poli_id,
                  satu_sehat_id: id_satu_sehat,
                })
                .then((res) => {
                  if(res.data.status == 200 && res.data.message == "sukses"){
                    vm.$store.commit("set_alert", { variant: "success", msg: "BERHASIL UPDATE DATA SATU SEHAT" })
                    vm.getDatas()
                  }
                  else{
                    vm.$store.commit("set_alert", { variant: "success", msg: res.data.message })
                  }
                })
                .catch((err) => {
                  vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
                })
              }else {
                vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message })
                // console.log('masuk sini');
              }
            })
            .catch((err) => {
              vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
            })
          }
        }else {
          vm.$store.commit("set_alert", { variant: "danger", msg: res.data.message })
        }
      })
      .catch((err) => {
        vm.$store.commit("set_alert", { variant: "danger", msg: err.message })
      })
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
    },
  },
};
</script>
